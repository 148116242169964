import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import logo from "../Images/logo_with_text.png";
import toast, { Toaster } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";

// import textLogo from "../Images/"
import image from "../Images/login_image.jpg";
import background from "../Images/login_background.jpg";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = Cookies.get("isAuthenticated") === "true";
  const [verified, setVerified] = useState(false);

  function onChange(value) {
    setVerified(true);
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);

    try {
      await toast.promise(
        // The promise to execute
        new Promise((resolve, reject) => {
          setTimeout(async () => {
            setIsLoggingIn(true);

            const requestData = {
              username: username,
              password: password,
            };

            const headers = {
              "Content-Type": "application/json",
              authorization: `${process.env.REACT_APP_LOGIN_KEY}`,
            };

            try {
              const response = await axios.post(
                "https://edsanchaar.in/api/api_admin/login.php",
                // "http://localhost:80/react/api_admin/login.php",
                requestData,
                { headers }
              );

              if (response.status === 200) {
                const data = response.data;
                console.log(data);

                if (data.success) {
                  Cookies.set("isAuthenticated", "true");
                  Cookies.set("encryptedApiKey", data.data.school_secure_key);
                  Cookies.set("roleId", data.data.encrypted_role_id.encrypted);
                  Cookies.set("role", data.data.encrypted_role.encrypted);
                  Cookies.set("schoolId", data.data.school_id.toString());
                  // Extract IVs from the response data
                  const roleIdIv = data.data.encrypted_role_id.iv;
                  const roleIv = data.data.encrypted_role.iv;

                  // Set IVs in cookies
                  Cookies.set("roleIdIv", roleIdIv);
                  Cookies.set("roleIv", roleIv);

                  toast.success("Login Successfully");
                  navigate("/dashboard");
                  resolve(); // Resolve the promise on success
                } else {
                  toast.error("Login failed. Please try again.");
                  reject(); // Reject the promise on failure
                }
              } else {
                toast.error("Login failed. Please try again.");
                reject(); // Reject the promise on failure
              }
            } catch (error) {
              console.error("An error occurred:", error);
              if (error.response) {
                toast.error("Login failed: " + error.response.data.message);
              } else {
                toast.error(
                  "An error occurred while processing your request. Please try again later."
                );
              }
              reject(); // Reject the promise on error
            } finally {
              setIsLoggingIn(false);
            }
          }, 4000); // 4s timeout
        }),
        {
          // Toast options
          loading: "Verifying Credentials Please Wait...",
          success: <b>Login Successfully!</b>,
          error: <b>Login failed. Please try again.</b>,
        }
      );
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <img
        src={background}
        alt="background image"
        className="h-[100%] w-[100%] opacity-30	"
      />
      <Toolbar
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
        }}
      >
        <Box
          sx={{
            // p: 4,
            borderRadius: 6,
            // backgroundColor: "#f5f5f5",

            border: 2,
            borderColor: "#6da2f0",
            backgroundColor: "#ffff",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "52%",
            height: "75%",
          }}
        >
          <div className=" overflow-hidden w-[90%] h-[100%] justify-center items-center rounded-l-2xl ">
            <img src={image} className="object-fill h-full rounded-l-2xl" />
          </div>
          <div className="flex flex-col justify-evenly items-center w-full h-full">
            <div className="flex items-center">
              <img
                src={logo}
                className="xl:w-22 xl:h-22 lg:w-16 lg:h-16 md:h-16 md:w-16"
                alt="Logo"
              />
            </div>

            <div
              id="loginform"
              className="bg-white p-6 rounded-3xl shadow-lg text-center flex flex-col justify-between items-center border border-slate-300 md:w-[24vw] md:h-[55vh] lg:w-[22vw] lg:h-[50vh] xl:w-[20vw]x l:h-[28vh]  2xl:w-[18vw] 2xl:h-[45vh] "
            >
              <div
                id="headerTitle"
                className="xl:text-xl 2xl:text-2xl lg:text-lg font-bold md:text-xs"
              >
                Login
              </div>
              <div className="flex flex-col h-[55%] md:h-auto justify-evenly bg-white">
                <div className="flex flex-col items-start bg-white ">
                  <label
                    htmlFor="username"
                    className="text-gray-700 text-base 2xl:text-lg xl:text-base lg:text-sm xs:text-xs font-bold"
                  >
                    Username
                  </label>
                  <input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    id="username"
                    className="w-full p-2 mt-1 border border-gray-300 rounded focus:shadow-outline focus:border-blue-300"
                    type="text"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <label
                    htmlFor="password"
                    className="text-gray-700 2xl:text-lg xl:text-base lg:text-sm xs:text-xs font-bold"
                  >
                    Password
                  </label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    className="w-full p-2 mt-1 border border-gray-300 rounded focus:shadow-outline focus:border-blue-300"
                    type="password"
                  />
                </div>
              </div>
              <ReCAPTCHA
                sitekey="6LfZZEUpAAAAABg6UqKu5DLtkEHCLU1TKAQ65Xrl"
                onChange={onChange}
                className="my-2 "
              />
              <button
                onClick={handleLogin}
                type="submit"
                disabled={isLoggingIn || !verified}
                className={`rounded-full py-2 px-6 font-bold ${
                  isLoggingIn || !verified
                    ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                    : "bg-blue-400 text-white hover:bg-blue-600"
                } transition ease-in-out duration-300`}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="footer-bottom fixed bottom-0 w-full bg-lightblue-800 p-4 text-center text-black">
            <p>© 2023 Ed-Sanchaar. All rights reserved.</p>
          </div>
        </Box>
      </Toolbar>
    </div>
  );
};

export default Login;
